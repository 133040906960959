<template>
  <div class="list">
    <div class="list-title">
      <Title :title="$t('txlist.name')" />
      <div class="info">
        <span>{{ $t("txlist.totalof") }}</span>
        <span class="num">{{
          $HelperTools.toFinancialVal(txs.TotalCount)
        }}</span>
        <span>{{ $t("txlist.block") }}</span>
      </div>
    </div>
    <List
      :data="txs.Transactions"
      :handleCurrentChange="handleCurrentChange"
      :rowDatas="rowDatas"
      :total="txs.TotalCount"
      :firstColumnWidth="230"
      :secondColumnWidth="115"
      :thirdColumnWidth="230"
      :fourthColumnWidth="230"
      :txHashColumn="0"
      :blockColumn="1"
      :fromColumn="2"
      :toColumn="3"
      :current-page="currentPage"
    />
  </div>
</template>

<script>
import { getTxs } from "@/api/_common";
import Title from "@/components/SecondaryTitle.vue";
import List from "@/components/List.vue";
import Helper from "@/utils/helper";

export default {
  components: {
    Title,
    List,
  },
  computed: {
    currentPage() {
      return Number(this.$route.query.page);
    },
  },
  data() {
    return {
      txs: {},
      pageSize: 10,
      rowDatas: [
        {
          prop: "Hash",
          label: "txlist.hash",
          method: (row) =>
            `${row.Hash.substr(0, 8)}...${row.Hash.substr(58, 8)}`,
          status: (row) => row.Status,
        },
        {
          prop: "Height",
          label: "txlist.height",
          method: (row) => row.BlockNumber,
        },
        {
          prop: "From",
          label: "txlist.from",
          method: (row) =>
            `${row.From.substr(0, 8)}...${row.From.substr(34, 8)}`,
        },
        {
          prop: "To",
          label: "txlist.to",
          method: (row) => `${row.To.substr(0, 8)}...${row.To.substr(34, 8)}`,
        },
        {
          prop: "Time",
          label: "txlist.time",
          method: (row) => Helper.getTransDate(row.Time),
        },
      ],
    };
  },
  watch: {
    $route: async function () {
      await this.getTxs();
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    },
  },
  async mounted() {
    if (!this.$route.query.page) {
      await this.$router.replace({
        query: {
          page: 1,
        },
      });
    }
    await this.getTxs();
  },
  methods: {
    async getTxs(page) {
      const res = await getTxs({
        PageNo: this.currentPage - 1,
        PageSize: this.pageSize,
      });
      this.txs = res;
    },
    async handleCurrentChange(newPage) {
      await this.$router.replace({
        query: {
          page: newPage,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/list.scss";
</style>
